import { UserService } from '@/service/backend/user.service'

const state =  {
    user: {
        id: "",
        name:   "", // Ha "-" van beállítva, akkor ki van jelentkezve
        email:  "",
        role:   "",
        profile_photo_url: ""
    }
}

const getters = {
    User: (state) => {
        return state.user
    },
    UserName: (state) => {
        return state.user.name
    },
    UserNameLetters: (state) => {
        if(state.user.name == undefined){ return "" }
        let i = state.user.name.indexOf(" ")
        let secondLetter = ""
        if(i>=0){ secondLetter = state.user.name.charAt(i+1) }
        return state.user.name.charAt(0) + secondLetter
    },
    UserEmail: (state) => {
        return state.user.email
    },
    UserRole: (state) => {
        return state.user.role
    },
    UserRoleName: (state, getters, rootState, rootGetters) => {
        let roleName = ""
        if(state.user.role !== ""){
            roleName = rootGetters['codeStore/userRole'](state.user.role)
        }
        return roleName
    }
}

const mutations = {
    setUserData(state, userData) {
        state.user.id           = userData.id
        state.user.name         = userData.name
        state.user.email        = userData.email
        if(userData.role !== undefined){
            state.user.role         = userData.role
        }
        state.user.profile_photo_url = userData.profile_photo_url
    },
    setUserLoggedOut(state) {
        state.user.name = "-"
    }
}

const actions = {

    async setUserData({commit}) {
        try{
            const userData = await UserService.requestUserData() 
            commit('setUserData', userData)
            return true
        }catch{
            commit('setUserLoggedOut')
            return false
        }
    },

    async updateUserData({commit}, userData) {
        await UserService.updateUserData(userData) 
        commit('setUserData', userData)
    },

    clearUserData({commit}) {
        const userData = { name: '-', email: '', role: '', roleName: '', profile_photo_url: '' } 
        commit('setUserData', userData)
    }
    
}

export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}