import { UserService, AuthenticationError } from '../service/backend/user.service'
import router from '@/router'

const state =  {
    authenticating: false,
    authenticationErrorCode: 0,
    authenticationError: '',
    accessCookie: false
}

const getters = {
    accessed: (state) => {
        return state.accessCookie ? true : false
    },
    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode
    },
    authenticationError: (state) => {
        return state.authenticationError
    },
}

const mutations = {
    loginRequest(state) {
        state.authenticating = true
        state.authenticationError = ''
        state.authenticationErrorCode = 0 
    },

    loginSuccess(state, accessCookie) {
        state.accessCookie = accessCookie
        state.authenticating = false
    },

    loginError(state, {errorCode, errorMessage}) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },

    logoutSuccess(state) {
        state.accessCookie = ''
    }

}


const actions = {

    async login({ commit }, {email, password}) {

        commit('loginRequest')

        try {

            const cookie = await UserService.login(email, password)

            commit('loginSuccess', cookie)

            // Redirect the user to the page he first tried to visit or to the home view

            router.push(router.currentRoute._value.query.redirect || '/');

            return true

        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }else{
                //console.log(e.message)
            }

            return false
        }

    },

    async loginWithAccessLink( { commit }, accessLinkCredentials) {

        commit('loginRequest')

        try {

            const cookie = await UserService.loginWithAccessLink(accessLinkCredentials)

            commit('loginSuccess', cookie)

            router.push('/');

            return true

        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }else{
                console.log(e.message)
            }

            return false
        }

    },

    logout({ commit }) {
        // TODO logout in server? Nem elég a cookie-t törölni helyben, és ha kicopyztam? 
        commit('logoutSuccess')
        let nextPage = { path: '/login' }
        if(router.currentRoute.fullPath !== "/"){
            nextPage.query = { redirect: router.currentRoute.fullPath } // Store the full path to redirect the user to after login
        } 
        router.push(nextPage)
    },

}

export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
