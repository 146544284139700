<template>
  <div id="app-container">
    <router-view/>
  </div>
</template>

<script>
export default {
    name: 'AppContainer'
}
</script>
