import {createRouter, createWebHistory} from 'vue-router';
import UserService from '@/service/backend/user.service'
import { store } from '@/store';

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { public: true, onlyWhenLoggedOut: true },
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        meta: { public: true, onlyWhenLoggedOut: true },
        component: () => import('./pages/ResetPassword.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPasswordToken',
        meta: { public: true, onlyWhenLoggedOut: true },
        component: () => import('./pages/ResetPassword.vue')
    },
    {
        path: '/',
        component: () => import('./App.vue'),
        children: [
            {
                path: '/kezdes',
                name: 'dashboard',
                meta: { roles: ['ADMIN', 'CARER'] },
                exact: true,
                component: () => import('./components/Dashboard.vue')
            },
            { 
                path: '/', 
                component: () => import('./components/Dashboard.vue'), 
                alias: '/kezdes' 
            },
            {
                path: '/profil',
                name: 'profile',
                exact: true,
                component: () => import('./components/UserProfile.vue')
            },
            {
                path: '/beteg/:patientId',
                name: 'patient',
                meta: { roles: ['ADMIN', 'CARER', 'MANAGER'] },
                component: () => import('./pages/otthonapolo/DetailsPatient.vue'),
            },
            {
                path: '/beteg',
                name: 'patients',
                meta: { roles: ['ADMIN', 'CARER', 'MANAGER'] },
                component: () => import('./pages/otthonapolo/CrudPatients.vue'),
            },
            {
                path: '/rendeles',
                name: 'orders',
                component: () => import('./pages/otthonapolo/CrudOrders.vue'),
            },
            {
                path: '/vizitkerelem',
                name: 'visitrequests',
                meta: { roles: ['ADMIN', 'CARER'] },
                component: () => import('./pages/otthonapolo/CrudVisitRequests.vue')
            },
            {
                path: '/teljesitesek',
                name: 'usercosts',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudUsercosts.vue'),
            },
            {
                path: '/teljesites',
                name: 'usercost',
                meta: { roles: ['CARER'] },
                component: () => import('./pages/otthonapolo/PageUsercost.vue'),
            },
            {
                path: '/riport/teljesites',
                name: 'riport-compliance',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/ReportCompliance.vue')
            },
            {
                path: '/riport/rendeles',
                name: 'riport-orders',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/ReportOrders.vue')
            },
            {
                path: '/riport/ujbetegek',
                name: 'riport-new-patients',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/ReportNewPatients.vue')
            },
            {
                path: '/felhasznalo',
                name: 'users',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudUsers.vue')
            },
            {
                path: '/orvos',
                name: 'doctors',
                meta: { roles: ['ADMIN', 'CARER'] },
                component: () => import('./pages/otthonapolo/CrudDoctors.vue')
            },
            {
                path: '/korhaz',
                name: 'hospitals',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudHospitals.vue')
            },
            {
                path: '/tapszer',
                name: 'nutritions',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudNutritions.vue')
            },
            {
                path: '/eszkoz',
                name: 'tools',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudTools.vue')
            },
            {
                path: '/vizittipus',
                name: 'visittypes',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudVisitTypes.vue')
            },
            {
                path: '/koltseg',
                name: 'costs',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudCosts.vue')
            },
            {
                path: '/kisterseg',
                name: 'areas',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudAreas.vue')
            },
            {
                path: '/telepules',
                name: 'subareas',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/CrudSubareas.vue')
            },
            {
                path: '/parameter',
                name: 'parameter',
                meta: { roles: ['ADMIN'] },
                component: () => import('./pages/otthonapolo/PageParameter.vue')
            },

/*  Innen az eredeti: 
            {
                path: '/formlayout',
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
                },
            },
            {
                path: '/invalidstate',
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
                },
            },
            {
                path: '/input',
                name: 'input',
                component: () => import('./components/InputDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
                },
            },
            {
                path: '/floatlabel',
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
                },
            },
            {
                path: '/button',
                name: 'button',
                component: () => import('./components/ButtonDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
                },
            },
            {
                path: '/table',
                name: 'table',
                component: () => import('./components/TableDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
                },
            },
            {
                path: '/list',
                name: 'list',
                component: () => import('./components/ListDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
                },
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
                },
            },
            {
                path: '/tree',
                name: 'tree',
                component: () => import('./components/TreeDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
                },
            },
            {
                path: '/panel',
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
                },
            },
            {
                path: '/overlay',
                name: 'overlay',
                component: () => import('./components/OverlaysDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
                },
            },
            {
                path: '/media',
                name: 'media',
                component: () => import('./components/MediaDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
                },
            },
            {
                path: '/menus',
                component: () => import('./components/MenusDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                        },
                    },
                    {
                        path: '/menus/seat',
                        component: () => import('./components/menu/SeatDemo.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                        },
                    },
                    {
                        path: '/menus/payment',
                        component: () => import('./components/menu/PaymentDemo.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                        },
                    },
                    {
                        path: '/menus/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                        },
                    },
                ],
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
                },
            },
            {
                path: '/file',
                name: 'file',
                component: () => import('./components/FileDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
                },
            },
            {
                path: '/chart',
                name: 'chart',
                component: () => import('./components/ChartsDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
                },
            },
            {
                path: '/misc',
                name: 'misc',
                component: () => import('./components/MiscDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
                },
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('./utilities/Icons.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
                },
            },
            {
                path: '/widgets',
                name: 'widgets',
                component: () => import('./utilities/Widgets.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
                },
            },
            {
                path: '/grid',
                name: 'grid',
                component: () => import('./utilities/GridDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
                },
            },
            {
                path: '/spacing',
                name: 'spacing',
                component: () => import('./utilities/SpacingDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
                },
            },
            {
                path: '/elevation',
                name: 'elevation',
                component: () => import('./utilities/ElevationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
                },
            },
            {
                path: '/typography',
                name: 'typography',
                component: () => import('./utilities/Typography.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
                },
            },
            {
                path: '/display',
                name: 'display',
                component: () => import('./utilities/DisplayDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
                },
            },
            {
                path: '/flexbox',
                name: 'flexbox',
                component: () => import('./utilities/FlexBoxDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
                },
            },
            {
                path: '/text',
                name: 'text',
                component: () => import('./utilities/TextDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
                },
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
                },
            },
            {
                path: '/calendar',
                name: 'calendar',
                component: () => import('./pages/CalendarDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
                },
            },
            {
                path: '/invoice',
                name: 'invoice',
                component: () => import('./pages/Invoice.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
                },
            },
            {
                path: '/help',
                name: 'help',
                component: () => import('./pages/Help.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Help' }],
                },
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
                },
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/Documentation.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
                },
            },
            */
        ]
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./pages/Logout.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { top: 0 }
        }
      },
});

router.beforeEach(async (to, from, next) => {

    const isPublic = to.matched.some(record => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
    const loggedIn = await UserService.loggedIn()

    if (!isPublic && !loggedIn && to.path != '/login') {
        let nextPage = { path: '/login' }
        if(to.fullPath !== "/"){
            nextPage.query = { redirect: to.fullPath } // Store the full path to redirect the user to after login
        } 
        return next(nextPage);
    }
  
    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
      return next('/')
    }

    if (loggedIn) {
        let User = store.getters['user/User']
        let Userrole = User.role
        const pathHasRole = to.matched.some(record => record.meta.roles)
        if(to.path == '/' && Userrole === "PHARMACY")
            return next('/rendeles')
        if(to.path == '/' && (Userrole === "ADMIN" || Userrole === "CARER" || Userrole === "MANAGER"))
            return next('/beteg')

        let hasRight = true
        if(pathHasRole){
            hasRight = to.matched.some(record => (record.meta.roles == undefined) ? false : record.meta.roles.includes(Userrole), Userrole)
        }
        if(!hasRight)
            return next('/notfound')
    }


    next()

})

export default router;

/*
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        } else {
          return { x: 0, y: 0 }
        }
    }
*/