import { createStore } from 'vuex'

import { auth } from './auth.store'
import { user } from './user.store'
import { codeStore } from './code.store'

//Vue.use(Vuex)

export const store = createStore({
    modules: {
        codeStore,
        auth,
        user
      },
})