import ApiService from './api.service'
import { store } from '@/store/'

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    /**
     * 
     * @returns access_token??? TODO
     * @throws AuthenticationError 
     * 
    **/
    login: async function(email, password) {

        try {
            await ApiService.get('sanctum/csrf-cookie')
            await ApiService.post('login',{ email, password })

            ApiService.mount401Interceptor()

            await store.dispatch('user/setUserData')

            return "Accesscookie: " + email + " - " + password

        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },

    /**
     * Logout the current user by removing the token from storage. 
     * 
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    async logout(is401 = false) {
        // Remove ... the token and remove Authorization header from Api Service as well 
        store.dispatch('user/clearUserData')
        store.dispatch('auth/logout')
        if(!is401) await ApiService.post('logout')
        ApiService.unmount401Interceptor()
    },

    // Bodnár Lajos Tibor - Saját kódok: 

    loginWithAccessLink: async function(accessLinkCredentials) {

        try {
            await ApiService.get('sanctum/csrf-cookie')
            await ApiService.post('reset-password', accessLinkCredentials)
            await this.login(accessLinkCredentials.email, accessLinkCredentials.password)

        } catch (error) {
            // TODO itt lehet, hogy nem email error jön...
            const eM = Object.values(error.response.data.errors)
            let eMessage = ""
            if(eM[0][0].includes('must be at least'))
                eMessage = "A jelszó minimum 8 karakter hosszú legyen!"
            else
                eMessage = eM[0][0]
            throw new AuthenticationError(error.response.status, eMessage)
        }
    },


    async loggedIn(withApi = true) {
        let ret = false
        const userLoggedIn = store.getters['user/UserName'];

        if( userLoggedIn !== undefined && userLoggedIn !== '-' && userLoggedIn !== '' ){
            ret = true;
        }else
        if( userLoggedIn !== '-' && withApi){
            ret = await store.dispatch('user/setUserData')
        }

        return ret
    },

    async requestUserData() {
        try {
            const response = await ApiService.get('api/user')

            // Ezt képernyő-betöltéskor (bejelentkezett felhasználónál) küldjük mindig
            ApiService.post('api/log', {
                type: 'requestUser',
                width: screen.availWidth,
                height: screen.availHeight
            })
            
            return response.data
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }

    },

    async updateUserData(data){
        const response = await ApiService.put('user/profile-information', data)
        return response.data
    },

    async updateUserPassword(passwords){
        const response = await ApiService.put('user/password', passwords)
        return response.data
    },
    
}

export default UserService

export { UserService, AuthenticationError }
