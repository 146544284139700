//import ApiService from '@/service/backend/api.service'

/*
Új kódtár felvitele: 
1. state.codeStore-ba felvenni
2. getters közé felvenni
 */

const state =  {
    codeStore: {
        //doctorClass: null // API használatakor a lehetséges codeStore-oknak itt null-el szerepelniük kell
        doctorClass: [
            { code: "1",    value: "Háziorvos" },
            { code: "2",    value: "Szakorvos" }, 
            { code: "9",    value: "Lejelentő" }
        ],
        userRole: [
            { code: "ADMIN",    value: "Admin" },
            { code: "PHARMACY", value: "Patika" }, 
            { code: "CARER",    value: "Ápoló" },
            { code: "MANAGER",  value: "Menedzser" }
        ]
    }
}

const getters = {
    doctorClass: (state) => {
        return state.codeStore.doctorClass
    },
    userRole: (state) => (code) => {
        return state.codeStore.userRole.find(x => x.code === code).value;
    },
    userRoles: (state) => {
        return state.codeStore.userRole
    }
}

// Innen általános (de API miatt még van TODO)

const mutations = {
    pushCode(state, { codeStoreName, newCodeStoreData }) {
        state.codeStore[codeStoreName] = newCodeStoreData
    }
}

const actions = {

    async loadCodeStore({commit}, codeStoreName) {
        //const codeStoreData = await ApiService.get('api/getCodeStore', {name: codeStoreName})
        // API használataok kellene ide ellenőrzés, hogy már üres-e vagy sem
        const codeStoreData = {
            data: [
                { code: "1", value: "Egy" }, { code: "2", value: "Kettő" }, { code: "9", value: "Kilenc" }
            ]
        } // MOCK - Apiservice használatakor törlendő
        const newCodeStoreData = codeStoreData.data
        await commit('pushCode', { codeStoreName, newCodeStoreData })
    }
    
}

export const codeStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}