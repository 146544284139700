import axios from 'axios'
import UserService from './user.service'

function onGetCall(config) {
    return config.method === 'get';
  }

const ApiService = {

   // Stores the 401 interceptor position so that it can be later ejected when needed
   //_401interceptor: null,

    baseURL : process.env.VUE_APP_ROOT_API,
    isLocal : (process.env.VUE_APP_NODE_NAME == "SERVE") ? true : false,

    init() {
        axios.defaults.withCredentials = true
        this.mount401Interceptor()
        this.mountGetHeaderInterceptor()
    },

    get(resource, data = "") {
        if(data === ""){
            return axios.get(this.baseURL + resource)
        }else{
            return axios.get(this.baseURL + resource, { params: data })
        }
    },

    post(resource, data) {
        return axios.post(this.baseURL + resource, data)       
    },

    put(resource, data) {
        return axios.put(this.baseURL + resource, data)
    },

    delete(resource) {
        return axios.delete(this.baseURL + resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
   // baseURL not defined here! 
    customRequest(data) {
        if(!this.isLocal){
            data.url = this.baseURL + data.url
            data.url = data.url.replace('//','/')
        }
        return axios(data)
    },

    // Amikor lejár a session, de benn vagyunk az app-ban, akkor a felhasználót át kell irányítani a login oldalra
    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            (response) => {
                return response
            },
            async (error) => {
                if (error.request.status == 401) {
                    const url = error.request.responseURL
                    const URLlastPart = url.substr(url.lastIndexOf("/")+1)
                    if(URLlastPart !== 'user'){         // csak akkor kell logout, ha nem /api/user -t hívtunk
                        await UserService.logout(true) // true => is401 miatti logout, nem lesz logout api hívás
                    }else{
                        throw error
                    }
                }else{
                    // If error was not 401 just reject as is
                    throw error
                }
            }
        )
    },

    unmount401Interceptor() {
        // Eject the interceptor
        axios.interceptors.response.eject(this._401interceptor)
    },

    mountGetHeaderInterceptor() {
        axios.interceptors.response.use(function (response) {
            if(response.headers['x-frontend-version'] !== undefined && process.env.VUE_APP_VERSION !== undefined
               && response.headers['x-frontend-version'] > process.env.VUE_APP_VERSION)
                location.reload(true);
            return response
          }, null, { runWhen: onGetCall });
    }

}

export default ApiService
